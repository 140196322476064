import React, { useEffect, useState } from "react";
import "./AddArtist.css";
import Navbar from "../Navbar/Navbar";
import photoImg from "../../Assets/photoImg.svg"; 
import arrowImg from '../../Assets/arrowleft.png';
import { useLocation, useNavigate } from "react-router-dom"; 
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore"; 
import { db } from "../firebase/firebase-config";
import { v4 as uuidv4 } from 'uuid';

function AddArtist() {
  const location = useLocation();
  const navigate = useNavigate(); 
  const [userID, setUserID] = useState();
  const [artistEventImage, setArtistEventImage] = useState(null); 
  const [artistEventName, setArtistEventName] = useState('');
  const [artistImageUrl, setArtistImageUrl] = useState(''); 
  const [showPopupArtist, setShowPopupArtist] = useState(false); 
  const [popupMessageArtist, setPopupMessageArtist] = useState(''); 
  const storage = getStorage();
  const addArtistID = uuidv4();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    setUserID(urlParams.get("UserID"));
  }, [location.search]);

  const AddArtistEvent = async () => {
    if (!artistEventImage || !artistEventName) {
      setPopupMessageArtist('Lütfen tüm bilgileri doldurun.');
      setShowPopupArtist(true);
      setTimeout(() => {
        setShowPopupArtist(false); 
      }, 3000);
      return; 
    }

    try {
      let uploadedArtistImageUrl = '';

      if (artistEventImage) {
        const imageRef = ref(getStorage(), `eventImages/${artistEventName}/${artistEventImage.name}`);
        const snapshot = await uploadBytes(imageRef, artistEventImage);
        uploadedArtistImageUrl = await getDownloadURL(snapshot.ref);
        setArtistImageUrl(uploadedArtistImageUrl);
      }

      const artistRef = doc(db, "artist", addArtistID);
      const searchText = artistEventName.toLowerCase().replace(/\s+/g, '_'); 
      
      await setDoc(artistRef, {
        title: artistEventName,
        searchText: searchText, 
        imageURL: uploadedArtistImageUrl,
        id: addArtistID
      });
      setShowPopupArtist(true);
      setPopupMessageArtist('Sanatçı başarıyla eklendi!');

      setTimeout(() => {
        setShowPopupArtist(false);
        navigate(-1); 
      }, 3000); 

    } catch (error) {
      console.error("Sanatçı eklenirken hata oluştu: ", error);
      setPopupMessageArtist("Sanatçı eklenemedi, tekrar deneyin.");
      setShowPopupArtist(true);
      setTimeout(() => {
        setShowPopupArtist(false);
      }, 2000);
    }
  };

  const handleArtistImageChange = (e) => {
    if (e.target.files[0]) {
      setArtistEventImage(e.target.files[0]); 
      const fileUrl = URL.createObjectURL(e.target.files[0]);
      setArtistImageUrl(fileUrl); 
    }
  };

  return (
    <div className="AddArtistMain">
      <Navbar UserID={userID} navbarImg2={arrowImg} navbarName={"sinartmedia"} />
      <div className="AddArtistContainer">
        <h1>Sanatçı Ekle</h1>
        <div className="AddArtistContent">
          <div className="AddArtistImgDiv">
            {artistImageUrl ? (
              <img src={artistImageUrl} alt="Uploaded" />
            ) : (
              <img src={photoImg} alt="Upload" />
            )}
            <h2 className="kapak">Kapak Resmi Yükle</h2>
            <input type="file" onChange={handleArtistImageChange} accept="image/*" />
          </div>
          <div className="AddArtistInputDiv">
            <label>Sanatçı Adı</label>
            <input 
              type="text" 
              onChange={(e) => setArtistEventName(e.target.value)} 
              placeholder="Sanatçı Adı" 
            />
          </div>
          <button onClick={AddArtistEvent}>Ekle</button>
        </div>
       
      </div>

      {showPopupArtist && (
          <div className="popup">
            <div className="popup-content">
              <p>{popupMessageArtist}</p>
            </div>
          </div>
        )}
    </div>
  );
}

export default AddArtist;
