import React from 'react'
import  './Dashboard.css'
import totalUserIcon from '../../Assets/totalUserIcon.svg'
import totalSalesIcon from '../../Assets/totalSalesIcon.svg'
import monthSalesIcon from '../../Assets/monthSalesIcon.svg'
import SalesDetail from './SalesDetail/SalesDetail'
import salesDetailImg from '../../Assets/salesDetailImg.png'
function Dashboard({UserID}) {
  return (
    <div className='DashboardMain'>
      <h1>DashBoard</h1>

      <div className='DashboardContentDiv'>
        <div className='totalUserDiv totalDiv'>
          <h1>Toplam Kullanıcı</h1>
          <h2>40,699</h2>
          <img src={totalUserIcon} />
        </div>

        <div className='totalSalesDiv totalDiv'>
          <h1>Toplam Satış</h1>
          <h2>10293</h2>
          <img src={totalSalesIcon} />
        </div>

        <div className='monthSalesDiv totalDiv'>
          <h1>Aylık Satış</h1>
          <h2>$89,000</h2>
          <img src={monthSalesIcon} />
        </div>
      </div>

      <div className='SalesDetailMain1'>
        <h1>Satış Detayları</h1>
        <div className='SalesDetailContextDiv'>
          <ul>
            <li>Etkinlik Adı</li>
            <li>Yer</li>
            <li>Zaman</li>
            <li>Adet</li>
            <li>Fiyat</li>
            <li>Durum</li>

          </ul>
        </div>
        
        <div className='EtkinlikSalesDetailDiv'>
          <SalesDetail salesDetailImg={salesDetailImg} salesTitle={"Ankara Etkinlik"} salesPalace={"6096 Marjolaine Landing"} salesTime={"12.09.2019 - 12.53 PM"} salesPiece={"100"} salesPrice={"34.295 ₺"} salesDurum={"Tamamlandı"}/>
          <SalesDetail salesDetailImg={salesDetailImg} salesTitle={"Ankara Etkinlik"} salesPalace={"6096 Marjolaine Landing"} salesTime={"12.09.2019 - 12.53 PM"} salesPiece={"100"} salesPrice={"34.295 ₺"} salesDurum={"Tamamlandı"}/>
          <SalesDetail salesDetailImg={salesDetailImg} salesTitle={"Ankara Etkinlik"} salesPalace={"6096 Marjolaine Landing"} salesTime={"12.09.2019 - 12.53 PM"} salesPiece={"100"} salesPrice={"34.295 ₺"} salesDurum={"Tamamlandı"}/>
          <SalesDetail salesDetailImg={salesDetailImg} salesTitle={"Ankara Etkinlik"} salesPalace={"6096 Marjolaine Landing"} salesTime={"12.09.2019 - 12.53 PM"} salesPiece={"100"} salesPrice={"34.295 ₺"} salesDurum={"Tamamlandı"}/>
          <SalesDetail salesDetailImg={salesDetailImg} salesTitle={"Ankara Etkinlik"} salesPalace={"6096 Marjolaine Landing"} salesTime={"12.09.2019 - 12.53 PM"} salesPiece={"100"} salesPrice={"34.295 ₺"} salesDurum={"Tamamlanmadı"}/>
         

        </div>
      </div>
    </div>
  )
}

export default Dashboard