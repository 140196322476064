import React from 'react';
import './EtkinlikCard.css';

function EtkinlikCard({ etkinlikCardImg, etkinlikName, etkinlikPrice, etkinlikPalace, etkinlikDate, etkinlikID, UserID, isConfirm, etkinlikQuota }) {
  const buttonArrange = () => {
    window.location.href = `/ArrangeEtkinlik?UserID=${UserID}&etkinlikID=${etkinlikID}&etkinlikTitle=${etkinlikName}&etkinlikStartDate=${etkinlikDate}&etkinlikImage=${etkinlikCardImg}&etkinlikPrice=${etkinlikPrice}&etkinlikQuota=${etkinlikQuota}`
  };

  return (
    <div className='EtkinlikCardMain'>
      <img src={etkinlikCardImg} alt={etkinlikName} />
      <h4>{etkinlikName}</h4>
      <p>{etkinlikPrice}</p>
      <p>{etkinlikPalace}</p>
      <p>{etkinlikDate}</p>
      <h6 className='gözükmeyecek'>{etkinlikQuota}</h6>
      <button className='duzenleButon' onClick={buttonArrange}>Düzenle</button>
      <button className={`isconfirmButton ${isConfirm === "Onaylanmamış" ? 'unapproved' : 'approved'}`}>
        {isConfirm || "Onaylandı"}
      </button>
    </div>
  );
}

export default EtkinlikCard;
