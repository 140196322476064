import React from 'react'
import './PastFatura.css'
function PastFatura({totalFaturaTitle, totalFatura, totalFaturaPriceTitle, totalFaturaPrice}) {
  return (
    <div className='PastFaturaMain'>
        <div className='totalFatura'>
            <h1>{totalFaturaTitle}</h1>
            <p>{totalFatura}</p>
        </div>

        <div className='totalFaturaPrice'>
            <h1>{totalFaturaPriceTitle}</h1>
            <p>{totalFaturaPrice}</p>
        </div>

        <div className='pastFaturaButtonDiv'>
            <button>Ödenmemiş</button>
        </div>

        <div className='PastFatura'>
            <h1>Geçmiş Faturalarım</h1>
        </div>
    </div>
  )
}

export default PastFatura