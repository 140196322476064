import React from 'react'
import './FaturaCardDetail.css'
function FaturaCardDetail({faturadetailTitle, faturadetailDate, faturadetailPerson, faturadetailtotalPerson}) {
  return (
    <div className='FaturaCardDetailMain'>
        <h1>{faturadetailTitle}</h1>
        <h2>{faturadetailDate}</h2>
        <h3>{faturadetailPerson}</h3>
        <h4>{faturadetailtotalPerson}</h4>
    </div>
  )
}

export default FaturaCardDetail