import React, { useEffect, useState } from 'react'
import './HomePage.css'
import Navbar from '../Navbar/Navbar'
import Sidebar from '../Sidebar/Sidebar'
import navbarImg2 from '../../Assets/menuImg.png'
import { useLocation } from 'react-router-dom'
import { setUserId } from 'firebase/analytics'
import { auth } from '../firebase/firebase-config'
function HomePage() {
  const [UserID, setUserID] = useState();





  const location = useLocation();
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    setUserID(urlParams.get("UserID"));


  

 
  }, [location.search]);



  return (
    <div>
      <Navbar  UserID={UserID} navbarImg2={navbarImg2} navbarName={"sineartmedia"} navbarTitle={"Biletinle.com"} />
      <Sidebar UserID={UserID} />
    </div>
  )
}

export default HomePage