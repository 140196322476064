import React, { useEffect, useState } from 'react';
import './AddPalace.css';
import Navbar from '../Navbar/Navbar';
import photoImg from '../../Assets/photoImg.svg';
import arrowImg from '../../Assets/arrowleft.png';
import { useLocation, useNavigate } from 'react-router-dom'; 
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase-config';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

function AddPalace() {
  const location = useLocation();
  const navigate = useNavigate(); 
  const [userID, setUserID] = useState();
  const [palaceEventImage, setPalaceEventImage] = useState(null); 
  const [palaceEventName, setPalaceEventName] = useState('');
  const [palaceEventAdress, setPalaceEventAdress] = useState('');
  const [palaceEventCity, setPalaceEventCity] = useState('');
  const [palaceImageUrl, setPalaceImageUrl] = useState(''); 
  const [showPopup, setShowPopup] = useState(false); 
  const [popupMessage, setPopupMessage] = useState(''); 
  const [showAlert, setShowAlert] = useState(false); 
  const [alertMessage, setAlertMessage] = useState(''); 
  const addPlaceID = uuidv4();
  
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    setUserID(urlParams.get("UserID"));
  }, [location.search]);

  const AddPalaceEvent = async () => {
    if (!palaceEventName || !palaceEventAdress ) {
      setPopupMessage('Mekan adı, adresi veya resim eksik. Lütfen tüm bilgileri doldurun.');
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false); 
      }, 3000);
      return; 
    }
  
    try {
      let uploadedPalaceImageUrl = '';
  
      if (palaceEventImage) {
        const imageRef = ref(getStorage(), `eventImages/${palaceEventName}/${palaceEventImage.name}`);
        const snapshot = await uploadBytes(imageRef, palaceEventImage);
        uploadedPalaceImageUrl = await getDownloadURL(snapshot.ref);
        setPalaceImageUrl(uploadedPalaceImageUrl);
      }
  
      const palaceRef = doc(db, "places", addPlaceID);
      const palaceSearchText = palaceEventName.toLowerCase().replace(/\s+/g, '_');
  
      await setDoc(palaceRef, {
        placeTitle: palaceEventName,
        adress: palaceEventAdress,
        searchText: palaceSearchText,
        imageURL: uploadedPalaceImageUrl,
        id: addPlaceID,
        city:palaceEventCity.toLocaleLowerCase('tr-TR'),
      });
  
      setPopupMessage('Mekan başarıyla eklendi!');
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false); 
        navigate(-1);
      }, 3000); 
    } catch (error) {
      console.error("Yer eklenirken hata oluştu: ", error);
      setPopupMessage('Mekan eklenemedi, lütfen tekrar deneyin.');
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false); 
      }, 3000);
    }
  };
  
  const handlePalaceImageChange = (e) => {
    if (e.target.files[0]) {
      setPalaceEventImage(e.target.files[0]); 
      const fileUrl = URL.createObjectURL(e.target.files[0]);
      setPalaceImageUrl(fileUrl); 
    }
  };

  const showKoltukMessage = () => {
    setAlertMessage('Bu özellik yakında gelecek!');
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false); 
    }, 3000); 
  };

  return (
    <div className='AddPalaceMain'>
        <Navbar navbarImg2={arrowImg} navbarName={"sinartmedia"} />
        <div className='AddPalaceContainer'>
            <h1>Mekan Ekle</h1>

            <div className="AddPalaceContent">
              <div className="AddPalaceImgDiv">
                {palaceImageUrl ? (
                  <img src={palaceImageUrl} alt="Uploaded"/>
                ) : (
                  <img src={photoImg} alt="Upload" />
                )}
                <h2 className="kapak">Kapak Resmi Yükle</h2>
                <input type="file" onChange={handlePalaceImageChange} accept="image/*" />
              </div>

              <div className="AddPalaceInputDiv">
                <label>Mekan Adı</label>
                <input onChange={(e) => setPalaceEventName(e.target.value)} type="text" placeholder="Mekan Adı" />

                <label>Mekan Adresi</label>
                <input onChange={(e) => setPalaceEventAdress(e.target.value)} type="text" placeholder="Mekan Adresi" />

                <label>Mekan Şehri</label>
                <input onChange={(e) => setPalaceEventCity(e.target.value)} type="text" placeholder="Mekan Şehri" />
              </div>
              <button onClick={AddPalaceEvent}>Ekle</button>
              <button onClick={showKoltukMessage}>Koltuk Durumu</button>
            </div>

        </div>

        {showPopup && (
          <div className="popup">
            <div className="popup-content">
              <p>{popupMessage}</p>
            </div>
          </div>
        )}

        {showAlert && (
          <div className="alert-message">
            <p>{alertMessage}</p>
          </div>
        )}
    </div>
  );
}

export default AddPalace;
