import React from 'react';
import './ChooseArtistCard.css';

function ChooseArtistCard({ chooseArtistImg, artistName, artistID, onSelect, popupMessage, popupVisible }) {
  const handleSelect = () => {
    onSelect();
  };

  return (
    <div className='fener'>
      <div className='ChooseArtistCardMain'>
        <div className='chooseArtistLeftDiv'>
          <img src={chooseArtistImg} alt={artistName} />
          <h1>{artistName}</h1>
        </div>
      
       <button  onClick={handleSelect}>
          Seç
        </button>
      </div>
      <div className='lineDiv'></div>

      {popupVisible && (
        <div className='popup'>
          <p>{popupMessage}</p>
        </div>
      )}
    </div>
  );
}

export default ChooseArtistCard;
