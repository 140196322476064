import React from 'react'
import FaturalarımHeader from './FaturalarımHeader/FaturalarımHeader'
import './Faturalarım.css'
import PastFatura from './PastFatura/PastFatura'
import FaturaDetail from './FaturaDetail/FaturaDetail'

function Faturalarım({UserID}) {
  return (
    <div className='FaturalarımMain'>
      <div>
        <FaturalarımHeader />
        <PastFatura 
          totalFatura={"1"} 
          totalFaturaTitle={"Toplam Fatura Adeti"} 
          totalFaturaPriceTitle={"Toplam Fatura Değeri"} 
          totalFaturaPrice={"7212,12 ₺"} 
        />
      </div>
      <div className='FaturaDetail1'>
        <FaturaDetail />
      </div>
    </div>
  )
}

export default Faturalarım
