import React from 'react'
import './Promotion.css'
import Navbar from '../Navbar/Navbar'
import arrowImg from '../../Assets/arrowleft.png'
function Promotion({title,smallTitle, explanation,placeholder}) {
  return (
    <div className='Promotion'>
        <Navbar navbarImg2={arrowImg} navbarName={"sinartmedia"} />
        <div className='PromotionMain'>
            <h1>{title}</h1>
            <div className='PromotionContent'>
            <label>{smallTitle}</label>
                  <textarea placeholder={placeholder}></textarea>
                  <p>{explanation}</p>
                  <button>Kaydet</button>

            </div>

        </div>
    </div>
  )
}

export default Promotion