import React from 'react'
import './EtkinliklerHeader.css'
import AddIcon from '@mui/icons-material/Add';
function EtkinliklerHeader({UserID}) {
  return (
    <div className='EtkinliklerHeaderMain'>
        <h1>Etkinlikler</h1>
        <a href={`/AddEtkinlik?UserID=${UserID}`}>
        <button>
          <AddIcon />
          <h1>Etkinlik Ekle</h1>
        </button>

        </a>
    </div>
  )
}

export default EtkinliklerHeader