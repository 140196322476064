import React from 'react';
import './SalesDetail.css';

function SalesDetail({ salesDetailImg, salesTitle, salesPalace, salesTime, salesPiece, salesPrice, salesDurum }) {
  const buttonStyle = {
    backgroundColor: salesDurum === "Tamamlandı" ? "green" : "red",
    color: "white",
    width: "93px",
height: "27px",
borderRadius: "13.5px",
fontSize: "8px",
border:"none",

  };

  return (
    <div className='SalesDetailMain2'>
      
      <div className='ares'>
        <img src={salesDetailImg} alt={salesTitle} />
        <p>{salesTitle}</p>
      </div>
      <p>{salesPalace}</p>
      <p>{salesTime}</p>
      <p>{salesPiece}</p>
      <p>{salesPrice}</p>
      <button style={buttonStyle}>{salesDurum}</button>
    </div>
  );
}

export default SalesDetail;
