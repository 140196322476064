import React from 'react'
import './FaturalarımHeader.css'
function FaturalarımHeader() {
  return (
    <div className='FaturalarımHeaderMain'>
        <h1>Faturalarım</h1>
    </div>
  )
}

export default FaturalarımHeader