import React from 'react'
import './FaturaDetail.css'
import FaturaCardDetail from './FaturaCardDetail/FaturaCardDetail'
function FaturaDetail() {
  return (
    <div className='FaturaDetailMain'>
        <div className='FaturaDetailContentDiv'>
            <ul>
                <li>ETKİNLİK</li>
                <li>ETKİNLİK TARİHİ</li>
                <li>KİŞİ</li>
                <li>TOPLAM</li>

            </ul>
        </div>

        <div className='FaturaDetailCardDiv'>
            <FaturaCardDetail faturadetailTitle={"ANKARA STAND-UP"} faturadetailDate={"23.12.2024"} faturadetailPerson={"12"} faturadetailtotalPerson={"12.324 ₺"} />
            <FaturaCardDetail faturadetailTitle={"ANKARA STAND-UP"} faturadetailDate={"23.12.2024"} faturadetailPerson={"12"} faturadetailtotalPerson={"12.324 ₺"} />
            <FaturaCardDetail faturadetailTitle={"ANKARA STAND-UP"} faturadetailDate={"23.12.2024"} faturadetailPerson={"12"} faturadetailtotalPerson={"12.324 ₺"} />
            <FaturaCardDetail faturadetailTitle={"ANKARA STAND-UP"} faturadetailDate={"23.12.2024"} faturadetailPerson={"12"} faturadetailtotalPerson={"12.324 ₺"} />
            <FaturaCardDetail faturadetailTitle={"ANKARA STAND-UP"} faturadetailDate={"23.12.2024"} faturadetailPerson={"12"} faturadetailtotalPerson={"12.324 ₺"} />
            <FaturaCardDetail faturadetailTitle={"ANKARA STAND-UP"} faturadetailDate={"23.12.2024"} faturadetailPerson={"12"} faturadetailtotalPerson={"12.324 ₺"} />
            <FaturaCardDetail faturadetailTitle={"ANKARA STAND-UP"} faturadetailDate={"23.12.2024"} faturadetailPerson={"12"} faturadetailtotalPerson={"12.324 ₺"} />
            <FaturaCardDetail faturadetailTitle={"ANKARA STAND-UP"} faturadetailDate={"23.12.2024"} faturadetailPerson={"12"} faturadetailtotalPerson={"12.324 ₺"} />
            <FaturaCardDetail faturadetailTitle={"ANKARA STAND-UP"} faturadetailDate={"23.12.2024"} faturadetailPerson={"12"} faturadetailtotalPerson={"12.324 ₺"} />
            <FaturaCardDetail faturadetailTitle={"ANKARA STAND-UP"} faturadetailDate={"23.12.2024"} faturadetailPerson={"12"} faturadetailtotalPerson={"12.324 ₺"} />

            <div className='FaturalarContextDiv'>
                <div className='FaturalarContextLeftDiv'>
                    <h1>BİLETİNLE.COM</h1>
                    <h2>VK:20078129694</h2>
                    <h2>VD:Kale</h2>
                    <h2>Adres:Şurası Burası sokak 6/3</h2>
                </div>

                <div className='FaturalarContextRightDiv'>
                    <button>Ödeme Talebi Oluştur</button>
                </div>
            </div>

          

        </div>
    </div>
  )
}

export default FaturaDetail