import './App.css';
import HomePage from './Components/HomePage/HomePage';
import AddEtkinlik from './Components/AddEtkinlik/AddEtkinlik'
import { Route, Routes, BrowserRouter, useNavigate} from 'react-router-dom';
import Campaign from './Components/Campaign/Campaign';
import Promotion from './Components/Promotion/Promotion';
import Login from './Components/Login/Login';
import ChooseArtist from './Components/ChooseArtist/ChooseArtist';
import ChoosePalace from './Components/ChoosePalace/ChoosePalace';
import AddArtist from './Components/AddArtist/AddArtist';
import AddPalace from './Components/AddPalace/AddPalace';
import ArrangeEtkinlik from './Components/ArrangeEtkinlik/ArrangeEtkinlik';
import { useEffect } from 'react';
function App({UserID}) {


  return (
    <div className="App">
   <Routes>
        <Route path='/HomePage' element={<HomePage/>}/>
        <Route path='/AddEtkinlik' element={<AddEtkinlik UserID={UserID}/>}/>
        <Route path='/Campaign' element={<Campaign/>}/>
        <Route path='/Promotion' element={<Promotion title={"Promosyon Ekle"} smallTitle={"Etkinlik Promosyonu"} />}/>
        <Route path='/Percentage' element={<Promotion title={"Yüzdelik İndirim Ekle"} smallTitle={"Yüzdelik İndirim"} explanation={"Not : Yüzdelik indirimi lütfen yüzde işareti koymadan sadece değer olarak girin."}/>}/>
        <Route path='/' element={<Login/>}/>
        <Route path='/ChooseArtist' element={<ChooseArtist/>}/>
        <Route path='/ChoosePalace' element={<ChoosePalace/>}/>
        <Route path='/AddArtist' element={<AddArtist/>}/>
        <Route path='/AddPalace' element={<AddPalace/>}/>
        <Route path='/ArrangeEtkinlik' element={<ArrangeEtkinlik/>}/>
      </Routes>
    </div>
  );
}

export default App;
