import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebase-config';

function Navbar({ navbarName, navbarTitle, navbarImg2, UserID }) {
  const navigate = useNavigate(); 

  const handleBackClick = () => {
    navigate(-1); 
  };

  const [organizatorList, setOrganizatorList] = useState([]); 
  const [loading, setLoading] = useState(true);
  
  const location = useLocation();


 
  useEffect(() => {
    const getOrganizatorList = async () => {
      try {
        const organizerListRef = collection(db, "organizer"); 
        const snapshots = await getDocs(organizerListRef); 

        const docs = snapshots.docs.map((doc) => {
          const data = doc.data();
          data.id = doc.id;
          return data;
        });

        setOrganizatorList(docs); 
        setLoading(false);
      } catch (error) {
        console.error("Error fetching organizator data: ", error);
        setLoading(false); 
      }
    };

    getOrganizatorList(); 
  }, []); 

  const filteredOrganizer = organizatorList.find(organizer => organizer.id === UserID);

  return (
    filteredOrganizer ? (
      <div className='NavbarMain'>
        <div className='NavbarTitleDiv'>
          <h1>{navbarTitle}</h1>
          <div className='NavbarMenuIcon' onClick={handleBackClick}>
            <img src={navbarImg2} alt="Back Icon" />
          </div>
        </div>
        <div className='NavbarRightDiv'>
          <img src={filteredOrganizer.imageURL} alt="Organizer" />
          <div className='NavbarContentDiv'>
            <h1>{filteredOrganizer.name}</h1>
            <h4>Organizatör</h4>
          </div>
        </div>
      </div>
    ) : (
      <p></p>
    )
  );
}

export default Navbar;
