import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import Dashboard from '../Dashboard/Dashboard'; 
import Etkinlikler from '../Etkinlikler/Etkinlikler'; 
import Faturalarım from '../Faturalarım/Faturalarım'; 
import { IoClose } from "react-icons/io5";
import sidebarImg2 from '../../Assets/menuImg.png';

function Sidebar({ UserID }) {
  const [selectedComponent, setSelectedComponent] = useState('Dashboard');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); 

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'Dashboard':
        return <Dashboard UserID={UserID} />;
      case 'Etkinlikler':
        return <Etkinlikler UserID={UserID} />;
      case 'Faturalarım':
        return <Faturalarım UserID={UserID} />;
      default:
        return null;
    }
  };

  return (
    <div className="sidebarMain">
      {isMobile && (
        <img 
          src={sidebarImg2} 
          alt="Navbar" 
          className="navbarImg2"
          onClick={() => setSidebarOpen(!sidebarOpen)} 
        />
      )}

      <div className={`Sidebar ${sidebarOpen && isMobile ? 'show' : ''}`}>
        {sidebarOpen && isMobile && (
          <button className="closeBtn" onClick={() => setSidebarOpen(false)}>
            <IoClose />
          </button>
        )}

        <ul className="SidebarMenu">
          <li className="SidebarMenuItem" onClick={() => setSelectedComponent('Dashboard')}>
            Dashboard
          </li>
          <li className="SidebarMenuItem" onClick={() => setSelectedComponent('Etkinlikler')}>
            Etkinlikler
          </li>
          <li className="SidebarMenuItem" onClick={() => setSelectedComponent('Faturalarım')}>
            Faturalarım
          </li>
          <li className="SidebarMenuItem" onClick={() => { console.log('Logging out...'); }}>
            Çıkış Yap
          </li>
        </ul>
      </div>
      
      <div className="SidebarContent">
        {renderComponent()}
      </div>
    </div>
  );
}

export default Sidebar;
