import React, { useEffect, useState } from 'react';
import './ChoosePalace.css';
import Navbar from '../Navbar/Navbar';
import ChooseArtistCard from '../ChooseArtist/ChooseArtistCard/ChooseArtistCard';
import chooseArtistImg from '../../Assets/choseArtistImg.png';
import arrowImg from '../../Assets/arrowleft.png';
import { useLocation } from 'react-router-dom';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase-config';
import { v4 as uuidv4 } from 'uuid';

function ChoosePalace() {
  const [etkinlikID, setEtkinlikID] = useState();
  const [userID, setUserID] = useState();
  const location = useLocation();
  const [etkinlikTitle, setEtkinlikTitle] = useState();
  const [etkinlikStartDate, setEtkinlikStartDate] = useState();
  const [etkinlikStartTime, setEtkinlikStartTime] = useState();
  const [etkinlikPrice, setEtkinlikPrice] = useState();
  const [etkinlikRules, setEtkinlikRules] = useState();
  const [etkinlikExplanation, setEtkinlikExplanation] = useState();
  const [etkinlikQuota, setEtkinlikQuota] = useState();
  const [etkinlikImage, setEtkinlikImage] = useState();
  const choosePlaceID = uuidv4();
  
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    setEtkinlikID(urlParams.get("etkinlikID"));
    setUserID(urlParams.get("UserID"));
    setEtkinlikTitle(urlParams.get("etkinlikTitle"));
    setEtkinlikStartDate(urlParams.get("etkinlikStartDate"));
    setEtkinlikStartTime(urlParams.get("etkinlikStartTime"));
    setEtkinlikPrice(urlParams.get("etkinlikPrice"));
    setEtkinlikRules(urlParams.get("etkinlikRules"));
    setEtkinlikExplanation(urlParams.get("etkinlikExplanation"));
    setEtkinlikQuota(urlParams.get("etkinlikQuota"));
    setEtkinlikImage(urlParams.get("etkinlikImage"));

  }, [location.search]);


  const [placeList, setPlaceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    const getPlaceList = async () => {
      try {
        const placelistRef = collection(db, "places");
        const snapshots = await getDocs(placelistRef);
        const docs = snapshots.docs.map((doc) => {
          const data = doc.data();
          data.id = doc.id;
          return data;
        });
        setPlaceList(docs);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching place data: ", error);
        setLoading(false);
      }
    };

    getPlaceList();
  }, []);

  const selectPlace = async (place) => {
    try {
      const addPlaceString = `organization/`;
      
    
      
      await setDoc(doc(db, addPlaceString, etkinlikID), {
        adress: place.adress,
        placeID: place.id,
        city:place.city.toLowerCase(),
        placeTitle:place.placeTitle,
      }, {merge:true});
  
      console.log(place.adress);
      setPopupMessage('Mekan Başarıyla Eklendi');  
      setTimeout(() => {
        window.location.href = `/ArrangeEtkinlik?UserID=${userID}&etkinlikID=${etkinlikID}&etkinlikTitle=${etkinlikTitle}&etkinlikStartDate=${etkinlikStartDate}&etkinlikStartTime=${etkinlikStartTime}&etkinlikRules=${etkinlikRules}&etkinlikExplanation=${etkinlikExplanation}&etkinlikQuota=${etkinlikQuota}&etkinlikImage=${etkinlikImage}&etkinlikPrice=${etkinlikPrice}`
      }, 2000);
      
    } catch (error) {
      console.error('Error selecting place: ', error);
      setPopupMessage('Mekan Eklenirken Bir Sorun Oluştu');  
    }
  
    setPopupVisible(true);
    setTimeout(() => {
      setPopupVisible(false);
    }, 2000);
  };
  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='ChooseArtistMain'>
      <Navbar UserID={userID} navbarName={"sineartmedia"} navbarImg2={arrowImg} />
      <div className='ChooseArtistMainContainer'>
        <div className='ChooseArtistHeader'>
          <h1>Mekan Seç</h1>
          <a href={`/AddPalace?UserID=${userID}`}><button>Ekle</button></a>
        </div>

        <div className='ChooseArtistContent'>
          {placeList.map((place) => (
            <ChooseArtistCard
              key={place.id}
              chooseArtistImg={place.imageURL}
              artistName={place.placeTitle}
              artistID={place.id}
              onSelect={(setPopupMessage, setPopupVisible) => selectPlace(place)}
              popupMessage={popupMessage}
              popupVisible={popupVisible} 
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ChoosePalace;
