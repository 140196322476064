import { initializeApp } from "firebase/app";
import {getAnalytics} from "firebase/analytics"
import 'firebase/compat/firestore';
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDFsw9cdTOUfXSBzhuxYGyz9Qe_qBd8yio",
  authDomain: "biletle-446ae.firebaseapp.com",
  projectId: "biletle-446ae",
  storageBucket: "biletle-446ae.appspot.com",
  messagingSenderId: "491416491649",
  appId: "1:491416491649:web:c0a1fa2100229912b95f80",
  measurementId: "G-816M6B9FHD"
};
const app = initializeApp(firebaseConfig);
getAnalytics(app);

 
export const db = getFirestore(app);
export const auth = getAuth(app);