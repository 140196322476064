import React, { useState } from 'react';
import './Login.css';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/firebase-config';

function Login() {
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [errorLoginText, setErrorLoginText] = useState("");
  const [loading, setLoading] = useState(false); 




  const handleLogin = async (event) => {
    event.preventDefault();
    setErrorLoginText(""); 
    setLoading(true); 

    const email = loginEmail;
    const modifiedEmail = email.replace(/\./g, '');
    try {
      await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
      window.location.href = `HomePage/?UserID=${modifiedEmail}`;

      console.log("Giriş başarılı");
      setLoading(false); 
    } catch (error) {
      setLoading(false); 
      console.error(error);

      if (error.code === "auth/wrong-password") {
        setErrorLoginText("HATA: Yanlış Şifre!");
      } else if (error.code === "auth/user-not-found") {
        setErrorLoginText("HATA: Kullanıcı Bulunamadı!");
      } else {
        setErrorLoginText("HATA: Giriş yapılamadı. Lütfen tekrar deneyin.");
      }
    }
  };

  return (
    <div className='LoginMain'>
      <h1>Biletinle.com LOGO</h1>

      <form onSubmit={handleLogin}> 
        <div className='inputGroup2'>
          <label htmlFor='email'>E-Posta</label>
          <input 
            type="email" 
            id="email" 
            name="email"  
            value={loginEmail} 
            onChange={(event) => setLoginEmail(event.target.value)} 
            placeholder='E-Posta' 
            required
          />
        </div>

        <div className='inputGroup2'>
          <label htmlFor='password'>Şifre</label>
          <input 
            type="password" 
            id="password" 
            name="password" 
            value={loginPassword}
            onChange={(event) => setLoginPassword(event.target.value)} 
            placeholder='Şifre' 
            required
          />
        </div>

        {errorLoginText && <p className='errorText'>{errorLoginText}</p>} 

        <div className='LoginButton'>
          <button type='submit' disabled={loading}> 
            {loading ? "Giriş Yapılıyor..." : "Giriş Yap"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default Login;
