import React, { useState, useEffect } from "react";
import "./ArrangeEtkinlik.css";
import photoImg from "../../Assets/photoImg.svg";
import Navbar from "../Navbar/Navbar";
import arrowImg from "../../Assets/arrowleft.png";
import { doc, getDoc, updateDoc, addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, getStorage } from "firebase/storage";
import { db } from "../firebase/firebase-config";
import { useLocation } from "react-router-dom";

function ArrangeEtkinlik() {
  const [eventName, setEventName] = useState("");
  const [etkinlikTitle, setEtkinlikTitle] = useState("");
  const [searchText, setSearchText] = useState("");
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventStartTime, setEventStartTime] = useState("");
  const [eventPrice, setEventPrice] = useState("");
  const [eventRules, setEventRules] = useState("");
  const [eventCategory, setEventCategory] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [ticketsSold, setTicketsSold] = useState("");
  const [eventImage, setEventImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [etkinlikID, setEtkinlikID] = useState();
  const [showPopupArrange, setShowPopupArrange] = useState(false); 
  const [popupMessageArrange, setPopupMessageArrange] = useState('');
  const storage = getStorage();
  const location = useLocation();
  const [userID, setUserID] = useState();

  const buttonArtist = () => {
    window.location.href = `/ChooseArtist?etkinlikID=${etkinlikID}&UserID=${userID}&etkinlikTitle=${etkinlikTitle}&etkinlikStartDate=${eventStartDate}&eventStartTime=${eventStartTime}&eventPrice=${eventPrice}&eventRules=${eventRules}&eventCategory=${eventCategory}&eventDescription=${eventDescription}&eventImage=${eventImage}&etkinlikQuota=${ticketsSold}`;
  };

  const buttonPlace = () => {
    window.location.href = `/ChoosePalace?etkinlikID=${etkinlikID}&UserID=${userID}&etkinlikTitle=${etkinlikTitle}&etkinlikStartDate=${eventStartDate}&eventStartTime=${eventStartTime}&eventPrice=${eventPrice}&eventRules=${eventRules}&eventCategory=${eventCategory}&eventDescription=${eventDescription}&eventImage=${eventImage}&etkinlikQuota=${ticketsSold}`;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    setUserID(urlParams.get("UserID"));
    setEtkinlikID(urlParams.get("etkinlikID"));
    setTicketsSold(urlParams.get("etkinlikQuota"));

    setEtkinlikTitle(urlParams.get("etkinlikTitle"));


  }, [location.search]);

  useEffect(() => {
    const fetchEventData = async () => {
      if (etkinlikID) {
        try {
          const eventRef = doc(db, "organization", etkinlikID);
          const eventSnap = await getDoc(eventRef);
          if (eventSnap.exists()) {
            const eventData = eventSnap.data();
            setEventName(eventData.title);
            setEventStartDate(eventData.startDate);
            setEventStartTime(eventData.startTime);
            setEventPrice(eventData.price);
            setEventRules(eventData.rules);
            setEventDescription(eventData.explanation);
            setImageUrl(eventData.imageURL);
            setEventCategory(eventData.category);
            setSearchText(eventData.title);
          } else {
            console.error("Etkinlik bulunamadı!");
          }
        } catch (error) {
          console.error("Veri çekilirken hata oluştu: ", error);
        }
      }
    };
    fetchEventData();
  }, [etkinlikID]);

  const handleUpdateEvent = async () => {
    if (!etkinlikID) {
      alert("Etkinlik ID bulunamadı!");
      return;
    }

    if (!eventName || !eventStartDate || !eventStartTime || !eventPrice || !eventRules || !eventCategory || !eventDescription) {
      setPopupMessageArrange('Lütfen tüm bilgileri doldurun.');
      setShowPopupArrange(true);
      setTimeout(() => {
        setShowPopupArrange(false); 
      }, 3000);
      return;
    }

    try {
      let uploadedImageUrl = imageUrl; 
      if (eventImage) {
        const imageRef = ref(getStorage(), `eventImages/${etkinlikID}/${eventImage.name}`);
        const snapshot = await uploadBytes(imageRef, eventImage);
        uploadedImageUrl = await getDownloadURL(snapshot.ref);
      }

      const eventRef = doc(db, "organization", etkinlikID);
      await updateDoc(eventRef, {
        title: eventName,
        searchText:eventName.toLowerCase(),
        startDate: eventStartDate,
        startTime: eventStartTime,
        price: eventPrice,
        rules: eventRules,
        quota: ticketsSold,

        explanation: eventDescription,
        imageURL: uploadedImageUrl,
        category: eventCategory,
      });

      setPopupMessageArrange('Etkinlik başarıyla güncellendi!');
      setTimeout(() => {
        window.location.href = `HomePage/?UserID=${userID}`
      
      }, 2000);
    } catch (error) {
      console.error("Etkinlik güncellenirken hata oluştu: ", error);
      setPopupMessageArrange('Etkinlik güncellenirken hata oluştu.');
    }

    setShowPopupArrange(true);
    setTimeout(() => {
      setShowPopupArrange(false);
    }, 2000);
  };

  const handleCreateNewEvent = async () => {
    if (!eventName || !eventStartDate || !eventStartTime || !eventPrice || !eventRules || !eventCategory || !eventDescription) {
      setPopupMessageArrange('Lütfen tüm bilgileri doldurun.');
      setShowPopupArrange(true);
      setTimeout(() => {
        setShowPopupArrange(false); 
      }, 3000);
      return;
    }

    try {
      let uploadedImageUrl = "";
      if (eventImage) {
        const imageRef = ref(getStorage(), `eventImages/${eventImage.name}`);
        const snapshot = await uploadBytes(imageRef, eventImage);
        uploadedImageUrl = await getDownloadURL(snapshot.ref);
      }

      await addDoc(collection(db, "organization"), {
        title: eventName,
        searchText:eventName.toLowerCase(),
        startDate: eventStartDate,
        startTime: eventStartTime,
        price: eventPrice,
        rules: eventRules,
        explanation: eventDescription,
        imageURL: uploadedImageUrl,
        category: eventCategory,
        ticketsSold: ticketsSold || 0,
      });

      setPopupMessageArrange('Yeni etkinlik başarıyla oluşturuldu!');
      setEventName("");
      setEventStartDate("");
      setEventStartTime("");
      setEventPrice("");
      setEventRules("");
      setEventDescription("");
      setEventCategory("");
      setEventImage(null);
      setImageUrl("");
      setTicketsSold("");
    } catch (error) {
      console.error("Yeni etkinlik oluşturulurken hata oluştu: ", error);
      setPopupMessageArrange('Yeni etkinlik oluşturulamadı, lütfen tekrar deneyin.');
    }

    setShowPopupArrange(true);
    setTimeout(() => {
      setShowPopupArrange(false);
    }, 3000);
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setEventImage(e.target.files[0]);
      setImageUrl(URL.createObjectURL(e.target.files[0])); 
    }
  };

  return (
    <div>
      <Navbar navbarImg2={arrowImg} navbarName={"sinartmedia"} />
      <div className="ArrangeEtkinlikMain">
        <div className="ArrangeEtkinlikHeaderDiv">
          <h1>Etkinliği Düzenle</h1>
          <a href="/Campaign">
            <button>Kampanya</button>
          </a>
        </div>

        <div className="ArrangeEtkinlikContentDiv">
          <img src={imageUrl || photoImg} alt="Kapak Resmi" />
          <div className="ImgfileDiv">
            <p className="kapakP">Kapak Resmi Yükle</p>
            <input type="file" onChange={handleImageChange} accept="image/*" />
          </div>

          <div className="inputGroup">
            <div className="inputPair">
              <label>Etkinlik Adı:</label>
              <input
                type="text"
                placeholder="Etkinlik Adı"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
              />
            </div>
            <div className="inputPair">
              <label>Etkinlik Başlangıç Tarihi:</label>
              <input
                type="date"
                value={eventStartDate}
                onChange={(e) => setEventStartDate(e.target.value)}
              />
            </div>
            <div className="inputPair">
              <label>Etkinlik Başlangıç Saati:</label>
              <input
                type="time"
                value={eventStartTime}
                onChange={(e) => setEventStartTime(e.target.value)}
              />
            </div>
            <div className="inputPair">
              <label>Ücret:</label>
              <input
                type="number"
                placeholder="Ücret"
                value={eventPrice}
                onChange={(e) => setEventPrice(e.target.value)}
              />
            </div>
            <div className="inputPair">
              <label>Etkinlik Kuralları:</label>
              <textarea
                placeholder="Etkinlik Kuralları"
                value={eventRules}
                onChange={(e) => setEventRules(e.target.value)}
              ></textarea>
            </div>

            <div className="inputPair">
              <label>Kategori:</label>
              <select
                value={eventCategory}
                onChange={(e) => setEventCategory(e.target.value)}
              >
                <option value="sinema">Sinema</option>
                <option value="konser">Konser</option>
                <option value="tiyatro">Tiyatro</option>
                <option value="festival">Festival</option>
                <option value="stand-up">Stand-Up</option>
              </select>
            </div>

            <div className="inputPair">
              <label>Etkinlik Açıklaması:</label>
              <textarea
                placeholder="Etkinlik Açıklaması"
                value={eventDescription}
                onChange={(e) => setEventDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="inputPair">
              <label>Kontenjan:</label>
              <input
                type="number"
                placeholder="Kontenjan"
                value={ticketsSold}
                onChange={(e) => setTicketsSold(e.target.value)}
              />
            </div>
          </div>

          <div className="arrangeEtkinlikButtomDiv">
            <div className="buttonsDiv">
              <button
                onClick={buttonArtist}
                className="artistButton"
              >
                Sanatçılar
              </button>
              <button
                onClick={buttonPlace}
                className="venueButton"
              >
                Mekanlar
              </button>
            </div>
            <div className="submitButtonDiv">
              <button className="submitButton" onClick={handleUpdateEvent}>
                Etkinliği Onaya Gönder
              </button>
              <button onClick={handleCreateNewEvent} className="submitButton">
                Yeni Etkinlik Olarak Gönder
              </button>
            </div>
          </div>
        </div>
      </div>
      {showPopupArrange && (
        <div className="popup">
          <div className="popup-content">
            <p>{popupMessageArrange}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ArrangeEtkinlik;
