import React from 'react';
import './Campaign.css';
import Navbar from '../Navbar/Navbar';
import arrowImg from '../../Assets/arrowleft.png'
function Campaign() {
  return (
    <div className='Campaign'>
        <Navbar navbarImg2={arrowImg} navbarName={"sinartmedia"}/>
        <div className='CampaignMain'>
            <h1>Kampanya Türü Seç</h1>
            <div className='CampaignContent'>
               <a href='/Promotion'>
               <button>Hediye Çarkına Ekle</button>
               </a>
               <a href='/Promotion'>
               <button>Promosyon Ekle</button>

               </a>
                <a href='/Promotion'>
                <button>Yüzdelik İndirim Ekle</button>

                </a>
            </div>
        </div>
    </div>
  );
}

export default Campaign;
