import React, { useEffect, useState } from 'react'; 
import EtkinliklerHeader from './EtkinliklerHeader/EtkinliklerHeader';
import EtkinlikCard from './EtkinlikCard/EtkinlikCard';
import etkinlikCardImg from '../../Assets/etkinlikCardImg.png'; 
import { db } from '../firebase/firebase-config';
import { collection, getDocs } from 'firebase/firestore';
import './Etkinlikler.css'
function Etkinlikler({UserID}) {
  const [etkinlikList, setEtkinlikList] = useState([]); 
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const getEtkinlikList = async () => {
      try {
        const etkinliklistRef = collection(db, "organization"); 
        const snapshots = await getDocs(etkinliklistRef); 

        const docs = snapshots.docs.map((doc) => {
          const data = doc.data();
          data.id = doc.id;
          return data;
        });

        setEtkinlikList(docs); 
        setLoading(false);
      } catch (error) {
        console.error("Error fetching etkinlik data: ", error);
        setLoading(false); 
      }
    };

    getEtkinlikList(); 
  }, []); 

  if (loading) {
    return <div>Loading...</div>; 
  }

  return (
    <div className='EtkinliklerMain'>
      <EtkinliklerHeader UserID={UserID} />
      <div className='etkinlikCardContainer'>
       {etkinlikList.map((etkinlik)=> {
return (
  <EtkinlikCard 
    UserID={UserID} 
    etkinlikID={etkinlik.id} 
    etkinlikCardImg={etkinlik.imageURL} 
    etkinlikName={etkinlik.title} 
    etkinlikDate={etkinlik.startDate} 
    etkinlikPalace={etkinlik.place} 
    etkinlikPrice={etkinlik.price} 
    etkinlikQuota={etkinlik.quota}
    isConfirm={etkinlik.isConfirm ? "" : "Onaylanmamış"} 
  />
);
       })}
      </div>
    </div>
  );
}

export default Etkinlikler;
