import React, { useEffect, useState } from 'react';
import './ChooseArtist.css';
import Navbar from '../Navbar/Navbar';
import ChooseArtistCard from './ChooseArtistCard/ChooseArtistCard';
import chooseArtistImg from '../../Assets/choseArtistImg.png';
import AddIcon from '@mui/icons-material/Add';
import arrowleft from '../../Assets/arrowleft.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from '../firebase/firebase-config';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

function ChooseArtist() {
  const location = useLocation();
  const [userID, setUserID] = useState();
  const [etkinlikID, setEtkinlikID] = useState();
  const [etkinlikTitle, setEtkinlikTitle] = useState();
  const [etkinlikStartDate, setEtkinlikStartDate] = useState();
  const [etkinlikStartTime, setEtkinlikStartTime] = useState();
  const [etkinlikPrice, setEtkinlikPrice] = useState();
  const [etkinlikRules, setEtkinlikRules] = useState();
  const [etkinlikExplanation, setEtkinlikExplanation] = useState();
  const [etkinlikQuota, setEtkinlikQuota] = useState();
  const [etkinlikImage, setEtkinlikImage] = useState();
  const [artistList, setArtistList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    setUserID(urlParams.get("UserID"));
    setEtkinlikID(urlParams.get("etkinlikID"));
    setEtkinlikTitle(urlParams.get("etkinlikTitle"));
    setEtkinlikStartDate(urlParams.get("etkinlikStartDate"));
    setEtkinlikStartTime(urlParams.get("etkinlikStartTime"));
    setEtkinlikPrice(urlParams.get("etkinlikPrice"));
    setEtkinlikRules(urlParams.get("etkinlikRules"));
    setEtkinlikExplanation(urlParams.get("etkinlikExplanation"));
    setEtkinlikQuota(urlParams.get("etkinlikQuota"));
    setEtkinlikImage(urlParams.get("etkinlikImage"));


  }, [location.search]);

  console.log("etkinlik ID :" + " " + etkinlikID);

  useEffect(() => {
    const getArtistList = async () => {
      try {
        const artistListRef = collection(db, "artist");
        const snapshots = await getDocs(artistListRef);

        const docs = snapshots.docs.map((doc) => {
          const data = doc.data();
          data.id = doc.id;
          return data;
        });

        setArtistList(docs);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching artist data: ", error);
        setLoading(false);
      }
    };

    getArtistList();
  }, []);

  const selectArtist = async (artist) => {
    try {
      const addArtistString = `organization/${etkinlikID}/artist`;

      await setDoc(doc(db, addArtistString, artist.id), {
        title: artist.title,
        id: artist.id,
        imageURL:artist.imageURL
      });

      setPopupMessage('Sanatçı Başarıyla Eklendi');
setTimeout(() => {
  window.location.href = `/ArrangeEtkinlik?UserID=${userID}&etkinlikID=${etkinlikID}&etkinlikTitle=${etkinlikTitle}&etkinlikStartDate=${etkinlikStartDate}&etkinlikStartTime=${etkinlikStartTime}&etkinlikRules=${etkinlikRules}&etkinlikExplanation=${etkinlikExplanation}&etkinlikQuota=${etkinlikQuota}&etkinlikImage=${etkinlikImage}&etkinlikPrice=${etkinlikPrice}`

}, 2000);
    
    } catch (error) {
      console.error('Error selecting artist: ', error);
      setPopupMessage('Sanatçı Eklenirken Bir Sorun Oluştu');
    }

    setPopupVisible(true);
    setTimeout(() => {
      setPopupVisible(false);
    }, 2000);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='ChooseArtistMain'>
      <Navbar UserID={userID} navbarName={"sinartmedia"} navbarImg2={arrowleft} />
      <div className='ChooseArtistMainContainer'>
        <div className='ChooseArtistHeader'>
          <h1>Sanatçı Seç</h1>
          <button>
            <AddIcon />
            <a href={`/AddArtist?UserID=${userID}`}><h1>Ekle</h1></a>
          </button>
        </div>

        <div className='ChooseArtistContent'>
          {artistList.map((artist) => (
            <ChooseArtistCard
              key={artist.id}
              onSelect={() => selectArtist(artist)}
              chooseArtistImg={artist.imageURL}
              artistName={artist.title}
              artistID={artist.id}
              searchText={artist.searchText}
              popupMessage={popupMessage}
              popupVisible={popupVisible}
            />
          ))}
        </div>

      </div>
    </div>
  );
}

export default ChooseArtist;
